import {  Action } from 'vuex-class';
import { SET_FROM_ANCHOR } from '@/store/actions';
import { Component, Vue } from 'vue-property-decorator';
import ButtonWishlist from '@/components/ButtonWishlist/ButtonWishlist.vue';
import IconCalendar from '@/assets/icons/calendar.svg?inline';
import Arrow from '@/assets/icons/arrow.svg?inline';
import ResponsiveImageRatio from '@/components/ResponsiveImageRatio/ResponsiveImageRatio.vue';
import { ImageWidths } from '@/common/interfaces/SizeBreakpoints';
import CarouselPromoCardViewModel from '@/common/interfaces/carousel/CarouselPromoCard';
import BadgeLabel from '@/components/BadgeLabel/BadgeLabel.vue';

const CarouselPromoCardProps = Vue.extend({
  props: { card: Object as () => CarouselPromoCardViewModel },
});

@Component({
  components: {
    ButtonWishlist,
    IconCalendar,
    Arrow,
    ResponsiveImageRatio,
    BadgeLabel,
  },
})
export default class CarouselPromoCard extends CarouselPromoCardProps {
  imageWidths: ImageWidths = {
    def: 463,
    sizes: {
      sm: 636,
    },
  };
  @Action(SET_FROM_ANCHOR) setLink!: (link: string) =>void;

  onCarouselLinkClick() {
    this.$emit('promoCardClicked');
    if (this.card.Src.startsWith('#')) this.setLink(this.card.Src);
  }
}
